<template>
  <div>
    <section class="to-expire d-flex">
      <div>
        <slot></slot>
      </div>
      <div>
        <div class="form-section">{{ translations.tcExpires }}</div>
        <b-form-datepicker id="anncDate" v-model="form.expires" class="mb-2 contact_date" :min="min"
          :locale="prefCulture" :label-help="translations.tcCalHelp"
          :label-no-date-selected="translations.tcDatePickerPlaceholder"
          :placeholder="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav"
          :hide-header="hideHeader">
          <template #button-content style="padding: 12px">
            <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
          </template>
        </b-form-datepicker>
      </div>
    </section>
    <section class="form-block">
      <b-form>
        <b-form-group class="form-element">
          <legend>{{ translations.tcTitle }}</legend>
          <b-form-input id="title-input" v-model="form.title"></b-form-input>
        </b-form-group>
        <b-form-group class="form-element">
          <legend>{{ translations.tcMessage }}</legend>
          <b-form-textarea id="message-textarea" v-model="form.message" rows="12" max-rows="16"></b-form-textarea>
        </b-form-group>
        <b-form-group class="form-element">
          <b-form-checkbox v-model="form.notification" id="send-notification" name="send-notification"
            :value="checkedValue" :unchecked-value="uncheckedValue">
            {{ translations.tcSendNotifications }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcMembersReceiveEmail">
              <i-tooltip />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="form-element form-attachment">
          <div class="form-section">
            {{ translations.tcAttachments }}
            <h6 class="size-limit">{{ translations.tcFileSizeMax }}</h6>
          </div>
          <div v-for="(att, idx) in attachments" :key="idx" style="width: 400px">
            <div class="attachment-list">
              <iPaperclip />
              <span>{{ att.name }}</span>
              <span style="cursor: pointer; float: right" :title="`${translations.tcDelete} ${att.name}`"
                @click="handleAttachmentDeleteClick(idx)">
                <serviceCardDelete />
              </span>
            </div>
          </div>
          <b-button style="margin-top: 20px" @click="handleRemoteClick()" variant="primary"
            class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0">{{ translations.tcAdd }}</b-button>
          <b-form-file style="display: none" multiple ref="attachment" v-model="form.fileArray"
            name="announcement_attachment" :browse-text="translations.tcBrowse"
            :placeholder="translations.tcFileToAttach" :drop-placeholder="translations.tcDropFileHere"
            @input="updateAttachments()" />
        </b-form-group>
      </b-form>
    </section>
    <section class="form-buttons">
      <b-button @click="handleBackClick" variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0">
        {{ translations.tcBack }}
      </b-button>
      <b-button variant="primary" @click="handleNextClick()" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0">
        {{ nextButtonText }}
      </b-button>
      <b-button @click="handleCancelClick()" variant="tertiary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0">
        {{ translations.tcCancel }}
      </b-button>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import serviceCardDelete from '@/assets/svgs/service-card-delete.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'announcement-template',
  mixins: [translationMixin, calendarMixin],
  props: {
    nextButtonText: {
      type: String,
      default: 'Next',
    },
    form: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      attachments: [],
      checkedValue: true,
      hideHeader: true,
      min: null,
      showDecadeNav: true,
      translations: {},
      uncheckedValue: false,
    }
  },
  components: {
    iPaperclip: iPaperclip,
    iTooltip: iTooltip,
    serviceCardDelete: serviceCardDelete,
  },
  computed: {
    ...mapGetters({
      announcementForm: 'announcements/announcementForm',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  methods: {
    ...mapActions({
      setAnnouncementForm: 'announcements/setAnnouncementForm',
    }),
    async validateForm() {
      try {
        let pass =
          !!this.form.expires &&
          !!this.form.title &&
          this.form.title.length > 0 &&
          !!this.form.message &&
          this.form.message.length > 0 &&
          new Date(this.form.expires).valueOf() > new Date().valueOf()
        this.form.expires = this.formatForDatePicker(this.form.expires.toString(), 'date').toISOString()
        return pass
      } catch (e) {
        return false
      }
    },
    handleBackClick() {
      this.$emit('backClick')
    },
    handleCancelClick() {
      this.$emit('announcement_cancel')
    },
    handleRemoteClick() {
      Object.assign(this.form.tempFileArray, [...this.form.fileArray, ...this.form.tempFileArray])
      this.$refs.attachment.$refs.input.click()
    },
    updateAttachments() {
      this.attachments = [...this.form.fileArray, ...this.form.tempFileArray]
    },
    handleAttachmentDeleteClick(nbr) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToDelete,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (result.value) {
          let tempList = [...this.form.fileArray, ...this.form.tempFileArray]
          tempList.splice(nbr, 1)
          this.form.fileArray = []
          this.form.tempFileArray = tempList
          this.updateAttachments()
        }
      })
    },
    async handleNextClick() {
      const val_result = await this.validateForm().then((rs) => {
        if (rs === true) {
          this.setAnnouncementForm(this.form)
          this.$emit('announcement_validated')
        } else {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorFillOutFields,
            confirmButtonText: this.translations.tcOk,
          })
        }
      })
    },
  },
  async created() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    this.min = new Date(today)

    await this.getComponentTranslations('announcement-template').then((response) => {
      this.$set(this, 'translations', response['announcement-template'])
    })

    if (!!this.form.expires) {
      this.form.expires = this.formatForDatePicker(this.form.expires.toString(), 'date')
    }
    // This step is necessary to preserve attached files when editing an announcement
    this.form.tempFileArray = this.form.fileArray.map((attachment) => {
      return attachment
    })
    this.form.fileArray = []
    this.updateAttachments()
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.attachment-list {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.size-limit {
  font-size: 0.8rem;
  font-style: italic;
}

.announcements-add {
  #title-input::placeholder {
    text-transform: uppercase;
    font-weight: bold;
  }

  .to-expire {
    margin-bottom: 40px;
    margin-top: 25px;
    justify-content: space-between;

    @include breakpoint(sm) {
      margin-bottom: 10px;
      flex-wrap: wrap;

      >div {
        &:first-of-type {
          margin-bottom: 60px;
        }

        &:last-of-type {
          width: 100%;

          .expires-date {
            text-transform: none;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }

  .button-group {
    .btn {
      font-weight: normal;
      text-transform: none;

      @include breakpoint(sm) {
        width: 50%;
      }

      &.btn-primary {
        font-weight: 800;
      }
    }
  }

  div.custom-checkbox {
    min-width: 300px;
  }

  .expires-date {
    color: #636363;
    font-weight: normal;

    svg {
      margin-right: 15px;
      height: 20px;
    }
  }

  .form-attachment {
    .attachment-list {
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }

      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: auto !important;
      }
    }
  }

  .start-end-app .start-input input[type='date'] {
    padding: 10px 20px 10px 60px;
  }

  .start-end-app {
    .start-input {
      &:after {
        content: 'Expiration Date';
      }
    }
  }
}
</style>
